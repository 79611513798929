import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        firstCoin: {
            id: null,
            large: null,
            market_cap_rank:  null,
            name: null,
            price: null,
            symbol: null,
            thumb: null,
            value: null,
            series: null,
        },
          secondCoin: {
            id: null,
            large: null,
            market_cap_rank:  null,
            name: null,
            price: null,
            symbol: null,
            thumb: null,
            value: null,
            series: null,
        },
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
    },
    
}
