import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
    },
    actions: {
        createCheckoutSession(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/payment/create-checkout-session", payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        createPortalSession() {
            return new Promise((resolve, reject) => {
                return api.post("/payment/create-portal-session").then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        }
    }

}
