import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        averageSentiment: null,
        topCoinPercentage: null,
        coinKpis: {
            totalRealizedRoi: 0,
            totalRealizedProfit: 0,
        },
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeAverageSentiment(state, averageSentiment) {
            state.averageSentiment = averageSentiment;
        },
        storeTopCoinPercentage(state, topCoinPercentage) {
            state.topCoinPercentage = topCoinPercentage;
        },
        storeCoinKpis(state, coinKpis) {
            state.coinKpis = coinKpis;
        }
    },
    actions: {
        fetchUserAddressesAverageCoinSentiment(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/coin/sentiment").then((response) => {
                    if (response.data) {
                        store.commit("storeAverageSentiment", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressAverageCoinSentiment(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/coin/sentiment/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeAverageSentiment", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUserAddressesTopCoinPercentage(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/coin/top").then((response) => {
                    if (response.data) {
                        store.commit("storeTopCoinPercentage", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressTopCoinPercentage(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/coin/top/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeTopCoinPercentage", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUserAddressesCoinKpis(store) {
            return new Promise((resolve, reject) => {
                return api.get("/financial/coin/kpi").then((response) => {
                    if (response.data) {
                        store.commit("storeCoinKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressCoinKpis(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/financial/coin/kpi/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeCoinKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
