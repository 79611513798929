import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        balances: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeBalances(state, balances) {
            state.balances = balances;
        },
    },
    actions: {
        fetchUserAddressesBalances(store) {
            return new Promise((resolve, reject) => {
                return api.get("/addressBalance/totalBalances").then((response) => {
                    if (response.data) {
                        store.commit("storeBalances", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressBalances(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/addressBalance/totalBalances/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeBalances", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
