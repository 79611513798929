import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        tags: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeUserTags(state, tags){
            state.tags = tags;
        },

    },
    actions: {
        fetchTags(store, payload){
            return api.get("/tag/search", payload).then((response) => {
                store.commit("storeUserTags", response.data)
            })
        }
    }

}
