import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        addresses: []
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        storeUserAddresses(state, addresses) {
            state.addresses = addresses;
        },
    },
    actions: {
        addPersonalUserAddress(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/userAddress/savePersonalUserAddress", payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((response) => reject(response));
            })
        },
        addPersonalUserExchange(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/userAddress/savePersonalUserExchange", payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((response) => reject(response));
            })
        },
        addWatchlistUserAddress(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/userAddress/saveWatchlistUserAddress", payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((response) => reject(response));
            })
        },
        syncUserAddresses(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/userAddress/syncUserAddresses", payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((response) => reject(response));
            })
        },
        fetchUserAddresses(store) {
            return new Promise((resolve, reject) => {
                return api.get("/userAddress/getUserAddresses").then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddresses", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchPersonalUserAddresses(store) {
            return new Promise((resolve, reject) => {
                return api.get("/userAddress/getPersonalUserAddresses").then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddresses", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchWatchlistUserAddresses(store) {
            return new Promise((resolve, reject) => {
                return api.get("/userAddress/getWatchlistUserAddresses").then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddresses", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        }
    }

}
