import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        unreadCount: 0,
        webNotifications: [],
        notificationChanged: true
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        storeUnreadCount(state, count) {
            state.unreadCount = count;
        },
        storeWebNotifications(state, notifications) {
            state.webNotifications = notifications;
        },
        storeNotificationChanged(state, notificationChanged) {
            state.notificationChanged = notificationChanged;
        }
    },
    actions: {
        fetchWebNotifications(store) {
            return new Promise((resolve, reject) => {
                return api.get("/notification/search").then((response) => {
                    if (response.data) {
                        store.commit("storeWebNotifications", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUnreadWebNotificationCount(store) {
            return new Promise((resolve, reject) => {
                return api.get("/notification/unread").then((response) => {
                        if (response.data != store.state.unreadCount) {
                            store.commit("storeUnreadCount", response.data)
                            store.commit("storeNotificationChanged", true)
                        } else {
                            store.commit("storeNotificationChanged", false)
                        }
                        resolve(response);                    
                }).catch((response) => reject(response));
            })
        },
        readWebNotifications() {
            return new Promise((resolve, reject) => {
                return api.post("/notification/read").then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        }
    }

}
