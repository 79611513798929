
import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

const defaultState = {
    userAddressTypes: ['PERSONAL', 'WATCHLIST'],
    selectedUserAddress: {
        id: null,
        name: null,
        address: {
            address: null,
        },
        userAddressType: null,
        apiKey: null,
        apiSecret: null,
        passphrase: null,
        exchangeType: null,
        tag: null,

    },
};

export default {
    namespaced: true,
    state: {
        userAddressTypes: ['PERSONAL', 'WATCHLIST'],
        selectedUserAddress: {
            id: null,
            name: null,
            address: {
                address: null,
            },
            userAddressType: null,
            apiKey: null,
            apiSecret: null,
            exchangeType: null,
            tag: null,
        },

    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        clearState(state) {
            Object.assign(state, defaultState);
        },
        storeUserAddress(state, userAddress) {
            state.selectedUserAddress = userAddress;
        }
    },
    actions: {
        resetState(store) {
            store.commit("clearState");
        },

        fetchUserAddress(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/userAddress/getUserAddress/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddress", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        updateUserAddress(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post(`/userAddress/updateUserAddress`, payload).then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddress", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        updateUserBookAddress(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post(`/userBookAddress/updateUserBookAddress`, payload).then((response) => {
                    if (response.data) {
                        store.commit("storeUserAddress", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        deleteUserBookAddress(store, address) {
            return new Promise((resolve, reject) => {
                return api.post(`/userBookAddress/deleteUserBookAddress/${address}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        addUserBookAddress(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/userBookAddress/saveUserBookAddress", payload)
                    .then((response) => {
                        if (response.data) {
                            store.commit("storeUserAddress", response.data)
                        }
                        resolve(response);
                    })
                    .catch((response) => reject(response));
            })
        },
        deleteUserAddress(store, address) {
            return new Promise((resolve, reject) => {
                return api.post(`/userAddress/deleteUserAddress/${address}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
