import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        fees: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeFees(state, fees) {
            state.fees = fees;
        },
    },
    actions: {
        fetchUserAddressesFees(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/totalFees").then((response) => {
                    if (response.data) {
                        store.commit("storeFees", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressFees(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/totalFees/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeFees", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
