import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        externalSource: {
            name: null,
            exchange: null,
            fileName: null,
            file: null,
        },
        selectedDocument: {
            id: null,
            name: null,
            exchange: null,
            fileName: null,
            file: null,
            documentStatusType: null,
            errorHint: null,
            errorType: null
        },
        files: [],
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
    },
    actions: {
        uploadExternalData(store, payload) {
            // Prepare the form data
            const formData = new FormData();
            formData.append("file", payload.file);
            formData.append("name", payload.name);
            formData.append("exchange", payload.exchange);
            return new Promise((resolve, reject) => {
                return api.post("/external-data/upload", formData).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            });
        },
        uploadMultipleExternalData(store, payload) {
            const formData = new FormData();
            for (var i = 0; i < payload.files.length; i++) {
                formData.append("files", payload.files[i].file);
            }
            formData.append("name", payload.name);
            formData.append("exchange", payload.exchange);
            return new Promise((resolve, reject) => {
                // Add header with content type multipart/form-data
                return api.post("/external-data/upload-multiple", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }).then((response) => {
                    resolve(response);
                }
                ).catch((response) => reject(response));

            });
        },
        updateMultipleExternalData(store, payload) {
            const formData = new FormData();
            for (var i = 0; i < payload.files.length; i++) {
                formData.append("files", payload.files[i].file);
            }
            formData.append("name", payload.name);
            return new Promise((resolve, reject) => {
                // Add header with content type multipart/form-data
                return api.post(`/external-data/update-multiple/${payload.documentId}`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }).then((response) => {
                    resolve(response);
                }
                ).catch((response) => reject(response));

            });
        },
        updateExternalData(store, payload) {
            // Prepare the form data
            const formData = new FormData();
            formData.append("file", payload.file);
            formData.append("name", payload.name);
            return new Promise((resolve, reject) => {
                return api.post(`/external-data/update/${payload.documentId}`, formData).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            });
        },
        deleteExternalData(store, documentId) {
            return new Promise((resolve, reject) => {
                return api.post(`/external-data/delete/${documentId}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}