import axios from 'axios'
import assign from 'lodash/assign'
import Vue from 'vue'

const BASE_API_URL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    return config
}, error => {
    return Promise.reject(error)
})

export default {
    get(url, extendedOptions) {
        var request = {
            url: BASE_API_URL + url,
            method: 'get'
        }
        if (extendedOptions) {
            assign(request, extendedOptions);
        }
        return axios(request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    post(url, request, extendedOptions) {
        var options = {
            url: BASE_API_URL + url,
            method: 'post',
            data: request
        }
        if (extendedOptions) {
            assign(options, extendedOptions);
        }
        return axios(options)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    delete(url) {
        var request = {
            url: BASE_API_URL + url,
            method: 'delete'
        }
        return axios(request)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }
}
