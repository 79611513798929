<template>
  <v-app>
    <v-navigation-drawer v-model="navigationMenu" bottom app clipped mobile-breakpoint="960"
      v-if="$vuetify.breakpoint.smAndDown">
      <navigation-bar-component :toggle="navigationMenu" />
    </v-navigation-drawer>
    <v-app-bar height="80px" app :color="$vuetify.theme.dark ? 'accent' : 'accent'" elevation="1">
      <v-app-bar-nav-icon color="white" @click="navigationMenu = !navigationMenu"
        v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
      <v-toolbar-title>
          <div class="pt-2 pl-7" >
            <v-badge :color="$vuetify.theme.dark ? 'accent' : 'accent'" :content="$t('app.version')" offset-x="7"
              offset-y="10">
              <v-img :src="getLogoImage" max-width="100" contain @click="$router.push('/')"></v-img>
            </v-badge>
          </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!$vuetify.breakpoint.smAndDown">
        <v-btn text color="white" link to="/overview">{{ $t('user.overviewPageButton') }}</v-btn>
        <v-btn text color="white" link to="/tax-center">
          {{ $t('tax.taxCenterPageButton') }}
        </v-btn>
        <v-btn text color="white" link to="/connection-management">
          {{ $t('connection.connectionManagement') }}
        </v-btn>
        <v-btn text color="white" link to="/pricing-plans">
           {{ $t('plan.pricingPlans') }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <template v-if="isLogged">
        <div>
          <v-icon class="ma-2" @click="changeVisibility" color="white" size="21px">{{ getVisibilityIcon }}</v-icon>
        </div>
        <div class="mx-3">
          <notification-component></notification-component>
        </div>
        <v-menu offset-y rounded right open-on-click>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="white--text ma-2">mdi-account-circle</v-icon>
          </template>
          <profile-dropdown-component />
        </v-menu>
      </template>
      <template v-else>
        <v-btn text @click="login">
          <span class="ma-2 white--text">Login</span>
          <v-icon color="white">mdi-login</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-container fluid class="pa-5 container-custom">
      <v-main>
        <v-row v-if="isAnyAddressUpdating" :align="'center'" :justify="'end'">
          <v-col :align="'end'" :justify="'end'">
            <v-tooltip bottom max-width="300px" min-width="300px">
              <template v-slot:activator="{ on }">
                <v-progress-circular class="ma-3 fixed" indeterminate v-on="on"></v-progress-circular>
              </template>
              <span>{{ $t('message.updatingAddress') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <router-view :key="$route.fullPath"></router-view>
        <snack-bar-alert />
      </v-main>
    </v-container>
    <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom small right color="accent" class="white--text"
      @click="toTop" v-if="$vuetify.breakpoint.xs">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import NavigationBarComponent from "./components/misc/NavigationBarComponent.vue";
import ProfileDropdownComponent from './components/misc/ProfileDropdownComponent.vue';
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import moment from 'moment'
import NotificationComponent from "./components/misc/NotificationComponent.vue";
export default {
  name: "App",
  components: {
    NavigationBarComponent,
    ProfileDropdownComponent,
    NotificationComponent,
  },
  data() {
    return {
      navigationMenu: false,
      visibility: null,
      fab: false
    };
  },
  computed: {
    ...mapFields("loggedUser", { locale: 'loggedUser.settings.language', theme: 'loggedUser.settings.theme' }),
    ...mapFields("userAddresses", ["addresses"]),
    isAnyAddressUpdating() {
      if (this.addresses) {
        return this.addresses.some(address => address.address.updating)
      }
      return false
    },
    isLogged() {
      return this.$keycloak ? this.$keycloak.authenticated : true;
    },
    getVisibilityIcon() {
      return this.visibility ? 'mdi-eye' : 'mdi-eye-off'
    },
    getLogoImage() {
      let logoImage = null;
      switch (this.theme) {
        case "DARK":
          logoImage = require('./assets/logo/logo_complete_w.svg') // require('./assets/logo/logo_complete_b.svg')
          break
        case "LIGHT":
          logoImage = require('./assets/logo/logo_complete_w.svg')
          break
        default:
          logoImage = require('./assets/logo/logo_complete_w.svg')
          break
      }
      return logoImage
    },
  },
  watch: {
    locale: function (newLocale, oldLocale) {
      if (newLocale != oldLocale) {
        this.$i18n.locale = newLocale.toLowerCase()
        this.$vuetify.lang.current = newLocale.toLowerCase()
        moment.locale(newLocale.toLowerCase())
      }
    },
    theme: function (newTheme, oldTheme) {
      if (newTheme != oldTheme) {
        switch (newTheme) {
          case "DARK":
            this.$vuetify.theme.dark = true;
            break
          case "LIGHT":
            this.$vuetify.theme.dark = false;
            break
        }
      }
    },
  },
  methods: {
    ...mapActions("userAddresses", ["fetchUserAddresses"]),
    ...mapActions("loggedUser", ["changeValueVisibility"]),
    changeVisibility() {
      this.visibility = !this.visibility
      this.$cookies.set("visibility", this.visibility)
      this.changeValueVisibility(this.visibility)
    },
    login() {
      if (this.$keycloak) {
        this.$keycloak.login();
      }

    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  },
  created() {
    if (this.$keycloak && this.$keycloak.authenticated) {
      this.fetchUserAddresses();
    }
    if (this.$cookies.isKey("visibility")) {
      this.visibility = this.$cookies.get("visibility") === 'true'
      this.changeValueVisibility(this.visibility)
    } else {
      this.visibility = true
      this.$cookies.set("visibility", this.visibility)
      this.changeValueVisibility(this.visibility)
    }
  },
};
</script>
<style>
.version-span {
  font-size: 0.8rem;
  font-weight: 300;
  padding-bottom: 2.2em;
  padding-left: 0.1em;
}

.v-application {
  background-color: var(--v-background-base) !important;
}
</style>
