import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';
import { i18n } from '../../../main'

export default {
    namespaced: true,
    state: {
        coinAllocations: [],
        defiAllocations: [],
        nftAllocations: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeCoinPortfolioAllocation(state, allocations) {
            state.coinAllocations = allocations;
        },
        storeDefiPortfolioAllocation(state, allocations) {
            state.defiAllocations = allocations;
        },
        storeNftPortfolioAllocation(state, allocations) {
            state.nftAllocations = allocations;
        },
    },
    actions: {
        fetchUserAddressesCoinPortfolioAllocation(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/portfolioAllocation/coin").then((response) => {
                    if (response.data) {
                        store.commit("storeCoinPortfolioAllocation", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressCoinPortfolioAllocation(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/portfolioAllocation/coin/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeCoinPortfolioAllocation", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUserAddressesNftPortfolioAllocation(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/portfolioAllocation/nft").then((response) => {
                    if (response.data) {
                        store.commit("storeNftPortfolioAllocation", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressNftPortfolioAllocation(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/portfolioAllocation/nft/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeNftPortfolioAllocation", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUserAddressesDefiPortfolioAllocation(store) {
            store.commit("storeDefiPortfolioAllocation", [{name: i18n.t('label.soon'), totalValue: Number.EPSILON}])
        },
        fetchAddressDefiPortfolioAllocation(store) {
            store.commit("storeDefiPortfolioAllocation", [{name: i18n.t('label.soon'), totalValue: Number.EPSILON}])
        }
    }

}
