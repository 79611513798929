<template>
  <v-card>
    <v-avatar :size="size" tile>
      <div class="avatar-image" v-html="getIdenticon"></div>
    </v-avatar>
  </v-card>
</template>

<script>
import jdenticon from 'jdenticon/dist/jdenticon';
export default {
  name: 'address-avatar-component',
  props: {
    name: {
      required: true
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "100"
    }
  },
  data() {
    return {
      defaultColor: 'primary'
    }
  },
  computed: {
    getColor() {
      if (this.color) {
        return this.color
      }
      return this.defaultColor
    },
    getIdenticon() {
      jdenticon.configure()
      return jdenticon.toSvg(this.name, this.size, this.color)
    }
  }

}
</script>

<style scoped>
.avatar-image {
  object-fit: cover;

}
</style>