import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify);

import it from 'vuetify/lib/locale/it'
import en from 'vuetify/lib/locale/en'


export default new Vuetify({
    // https://vuetifyjs.com/en/features/internationalization/#getting-started
    lang: {
      locales: {it, en},
      current: 'en'
    },
    icons: {
        iconfont: 'mdi' || 'md' || 'fa',
    },
    // https://vuetifyjs.com/en/features/theme/#customizing
    theme: {
      options: {
        // https://stackoverflow.com/questions/48280990/using-custom-theming-in-vuetify-and-pass-color-variables-to-components
        customProperties: true
      },
        themes: {
            light: {
              primary: '#121212',
              secondary: '#ffffff',
              accent: '#4479fa',
              error: '#FF5252',
              info: '#4479fa',
              success: '#4CAF50',
              warning: '#FFC107',
              anchor: '#8c9eff',
              background: '#f0f1f3',
            },
            dark: {
              primary: '#ffffff',
              secondary: '#101f33',
              accent: '#323e5f',
              error: '#FF5252',
              info: '#323e5f',
              success: '#4CAF50',
              warning: '#FFC107',
              anchor: '#8c9eff',
              background: '#121212',
            }
        }
    },
});
