import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        newsFeeds: null,
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeFeeds(state, newsFeeds) {
            state.newsFeeds = newsFeeds;
        },
    },
    actions: {
        fetchNews(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/news/search", payload).then((response) => {
                    if (response.data) {
                        store.commit("storeFeeds", response.data.newsFeeds)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
