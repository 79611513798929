import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
    },
    actions: {
        requestFinancialReport(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/financial/document/create-financial-report", payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            });
        },
        deleteFinancialDocument(store, documentId) {
            return new Promise((resolve, reject) => {
                return api.post(`/financial/document/delete/${documentId}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
