import Vue from 'vue';
import { getNewsFeedTypeFromString, truncateString, getSpaceFromCurrencySymbol, getCurrencySymbolFromString, getCurrencyDecimalsFromString, getBlockchainTypeFromString, getWalletTypeFromString, getPlatformTypeFromString, getScanBaseUrlFromBlockchainType, getCurrencyFromBlockchainType, getGroupValueFromTransactionActionType } from "@/utils/utils";
import { i18n } from '../main'
import moment from 'moment'
import userAddressesStore from '../store/modules/user/userAddresses'
import loggedUserStore from '../store/modules/user/loggedUser'

const exchangeAddresses = {
    "0x3f5ce5fbfe3e9af3971dd833d26ba9b5c936f0be": "Binance",
    "0x85b931a32a0725be14285b66f1a22178c672d69b": "Binance 10",
    "0x708396f17127c42383e3b9014072679b2f60b82f": "Binance 11",
    "0xe0f0cfde7ee664943906f17f7f14342e76a5cec7": "Binance 12",
    "0x8f22f2063d253846b53609231ed80fa571bc0c8f": "Binance 13",
    "0x28c6c06298d514db089934071355e5743bf21d60": "Binance 14",
    "0x21a31ee1afc51d94c2efccaa2092ad1028285549": "Binance 15",
    "0xdfd5293d8e347dfe59e90efd55b2956a1343963d": "Binance 16",
    "0x56eddb7aa87536c09ccc2793473599fd21a8b17f": "Binance 17",
    "0x9696f59e4d72e237be84ffd425dcad154bf96976": "Binance 18",
    "0x4d9ff50ef4da947364bb9650892b2554e7be5e2b": "Binance 19",
    "0xd551234ae421e3bcba99a0da6d736074f22192ff": "Binance 2",
    "0x4976a4a02f38326660d17bf34b431dc6e2eb2327": "Binance 20",
    "0xd88b55467f58af508dbfdc597e8ebd2ad2de49b3": "Binance 21",
    "0x7dfe9a368b6cf0c0309b763bb8d16da326e8f46e": "Binance 22",
    "0x345d8e3a1f62ee6b1d483890976fd66168e390f2": "Binance 23",
    "0xc3c8e0a39769e2308869f7461364ca48155d1d9e": "Binance 24",
    "0x2e581a5ae722207aa59acd3939771e7c7052dd3d": "Binance 25",
    "0x44592b81c05b4c35efb8424eb9d62538b949ebbf": "Binance 26",
    "0xa344c7ada83113b3b56941f6e85bf2eb425949f3": "Binance 27",
    "0x5a52e96bacdabb82fd05763e25335261b270efcb": "Binance 28",
    "0x06a0048079ec6571cd1b537418869cde6191d42d": "Binance 29",
    "0x564286362092d8e7936f0549571a803b203aaced": "Binance 3",
    "0x892e9e24aea3f27f4c6e9360e312cce93cc98ebe": "Binance 30",
    "0x00799bbc833d5b168f0410312d2a8fd9e0e3079c": "Binance 31",
    "0x141fef8cd8397a390afe94846c8bd6f4ab981c48": "Binance 32",
    "0x50d669f43b484166680ecc3670e4766cdb0945ce": "Binance 33",
    "0x2f7e209e0f5f645c7612d7610193fe268f118b28": "Binance 34",
    "0xd9d93951896b4ef97d251334ef2a0e39f6f6d7d7": "Binance 35",
    "0x19184ab45c40c2920b0e0e31413b9434abd243ed": "Binance 39",
    "0x0681d8db095565fe8a346fa0277bffde9c0edbbf": "Binance 4",
    "0x294b9b133ca7bc8ed2cdd03ba661a4c6d3a834d9": "Binance 41",
    "0x5d7f34372fa8708e09689d400a613eee67f75543": "Binance 42",
    "0xfe9e8709d3215310075d67e3ed32a380ccf451c8": "Binance 5",
    "0x4e9ce36e442e55ecd9025b9a6e0d88485d628a67": "Binance 6",
    "0xbe0eb53f46cd790cd13851d5eff43d12404d33e8": "Binance 7",
    "0xf977814e90da44bfa03b6295a0616a897441acec": "Binance 8",
    "0x001866ae5b3de6caa5a51543fd9fb64f524f5478": "Binance 9",
    "0x8b99f3660622e21f2910ecca7fbe51d654a1517d": "Binance Charity",
    "0xab83d182f3485cf1d6ccdd34c7cfef95b4c08da4": "Binance JEX",
    "0xc365c3315cf926351ccaf13fa7d19c8c4058c8e1": "Binance Pool",
    "0x61189da79177950a7272c88c6058b96d4bcd6be2": "Binance US",
    "0x4fabb145d64652a948d72533023f6e7a623c7c53": "Binance USD",
    "0xc9a2c4868f0f96faaa739b59934dc9cb304112ec": "Binance: BGBP Token",
    "0x47ac0fb4f2d84898e4d9e7b4dab3c24507a6d503": "Binance: Binance-Peg Tokens",
    "0xb8c77482e45f1f44de1745f52c74426c631bdd52": "Binance: BNB Token",
    "0x0b95993a39a363d99280ac950f5e4536ab5c5566": "Binance: Contract",
    "0x1074253202528777561f83817d413e91bfa671d4": "Binance: Deployer 3",
    "0x170c7c38419767816cc7ec519da67d1a4dc43826": "Binance: Deposit 1",
    "0x9430801ebaf509ad49202aabc5f5bc6fd8a3daf8": "Binance: Deposit Funder",
    "0x8f80c66c70cbc52009babb04c1cadf9b40109289": "Binance: Deposit Funder 2",
    "0x15ece0d7de25436bcfcf3d62a9085ddc7838aee9": "Binance: Deposit Funder 3",
    "0x2f47a1c2db4a3b78cda44eade915c3b19107ddcc": "Binance: Eth2 Depositor",
    "0x043a80999cee3711d372fb878768909fbe7f71e6": "Binance: Industry Recovery Initiative",
    "0xb3f923eabaf178fc1bd8e13902fc5c61d3ddef5b": "Wintermute: Binance Deposit",
    "0xab5c66752a9e8167967685f1450532fb96d5d24f": "Huobi 1",
    "0xe93381fb4c4f14bda253907b18fad305d799241a": "Huobi 10",
    "0xfa4b5be3f2f84f56703c42eb22142744e95a2c58": "Huobi 11",
    "0x46705dfff24256421a05d056c29e81bdc09723b8": "Huobi 12",
    "0x32598293906b5b17c27d657db3ad2c9b3f3e4265": "Huobi 13",
    "0x5861b8446a2f6e19a067874c133f04c578928727": "Huobi 14",
    "0x926fc576b7facf6ae2d08ee2d4734c134a743988": "Huobi 15",
    "0xeec606a66edb6f497662ea31b5eb1610da87ab5f": "Huobi 16",
    "0x7ef35bb398e0416b81b019fea395219b65c52164": "Huobi 17",
    "0x229b5c097f9b35009ca1321ad2034d4b3d5070f6": "Huobi 18",
    "0xd8a83b72377476d0a66683cde20a8aad0b628713": "Huobi 19",
    "0x6748f50f686bfbca6fe8ad62b22228b87f31ff2b": "Huobi 2",
    "0x90e9ddd9d8d5ae4e3763d0cf856c97594dea7325": "Huobi 20",
    "0x30741289523c2e4d2a62c7d6722686d14e723851": "Huobi 21",
    "0x6f48a3e70f0251d1e83a989e62aaa2281a6d5380": "Huobi 22",
    "0xf056f435ba0cc4fcd2f1b17e3766549ffc404b94": "Huobi 23",
    "0x137ad9c4777e1d36e4b605e745e8f37b2b62e9c5": "Huobi 24",
    "0x5401dbf7da53e1c9dbf484e3d69505815f2f5e6e": "Huobi 25",
    "0x034f854b44d28e26386c1bc37ff9b20c6380b00d": "Huobi 26",
    "0x0577a79cfc63bbc0df38833ff4c4a3bf2095b404": "Huobi 27",
    "0x0c6c34cdd915845376fb5407e0895196c9dd4eec": "Huobi 28",
    "0x794d28ac31bcb136294761a556b68d2634094153": "Huobi 29",
    "0xfdb16996831753d5331ff813c29a93c76834a0ad": "Huobi 3",
    "0x34189c75cbb13bdb4f5953cda6c3045cfca84a9e": "Huobi 30",
    "0xb4cd0386d2db86f30c1a11c2b8c4f4185c1dade9": "Huobi 31",
    "0x4d77a1144dc74f26838b69391a6d3b1e403d0990": "Huobi 32",
    "0x28ffe35688ffffd0659aee2e34778b0ae4e193ad": "Huobi 33",
    "0xcac725bef4f114f728cbcfd744a731c2a463c3fc": "Huobi 34",
    "0x73f8fc2e74302eb2efda125a326655acf0dc2d1b": "Huobi 35",
    "0x0a98fb70939162725ae66e626fe4b52cff62c2e5": "Huobi 36",
    "0xf66852bc122fd40bfecc63cd48217e88bda12109": "Huobi 37",
    "0x49517ca7b7a50f592886d4c74175f4c07d460a70": "Huobi 38",
    "0x58c2cb4a6bee98c309215d0d2a38d7f8aa71211c": "Huobi 39",
    "0xeee28d484628d41a82d01e21d12e2e78d69920da": "Huobi 4",
    "0x39d9f4640b98189540a9c0edcfa95c5e657706aa": "Huobi 40",
    "0x5c985e89dde482efe97ea9f1950ad149eb73829b": "Huobi 5",
    "0xdc76cd25977e0a5ae17155770273ad58648900d3": "Huobi 6",
    "0xadb2b42f6bd96f5c65920b9ac88619dce4166f94": "Huobi 7",
    "0xa8660c8ffd6d578f657b72c0c811284aef0b735e": "Huobi 8",
    "0x1062a747393198f70f71ec65a582423dba7e5ab3": "Huobi 9",
    "0x9d6d492bd500da5b33cf95a5d610a73360fcaaa0": "Huobi Mining Pool",
    "0xa66daa57432024023db65477ba87d4e7f5f95213": "Huobi Pool Token",
    "0x6f259637dcd74c767781e37bc6133cd6a68aa161": "Huobi Token",
    "0xe0d513cf39b52a09aefcef25e91dda3a2636329a": "Huobi: Deposit",
    "0xfd54078badd5653571726c3370afb127351a6f26": "Huobi: Deposit Funder",
    "0x18916e1a2933cb349145a280473a5de8eb6630cb": "Huobi: Deposit Funder 2",
    "0xdb0e89a9b003a28a4055ef772e345e8089987bfd": "Huobi: Deposit Funder 3",
    "0xf0458aaaf6d49192d3b4711960635d5fa2114e71": "Huobi: Deposit Funder 4",
    "0x07ef60deca209ea0f3f3f08c1ad21a6db5ef9d33": "Huobi: Deposit Funder 5",
    "0x0316eb71485b0ab14103307bf65a021042c6d380": "Huobi: HBTC Token",
    "0x1d1e10e8c66b67692f4c002c0cb334de5d485e41": "Huobi: Old Address 1",
    "0xea0cfef143182d7b9208fbfeda9d172c2aced972": "Huobi: Old Address 10",
    "0x0c92efa186074ba716d0e2156a6ffabd579f8035": "Huobi: Old Address 11",
    "0x91dfa9d9e062a50d2f351bfba0d35a9604993dac": "Huobi: Old Address 12",
    "0x8e8bc99b79488c276d6f3ca11901e9abd77efea4": "Huobi: Old Address 13",
    "0xb9a4873d8d2c22e56b8574e8605644d08e047549": "Huobi: Old Address 14",
    "0x170af0a02339743687afd3dc8d48cffd1f660728": "Huobi: Old Address 15",
    "0xf775a9a0ad44807bc15936df0ee68902af1a0eee": "Huobi: Old Address 16",
    "0x75a83599de596cbc91a1821ffa618c40e22ac8ca": "Huobi: Old Address 17",
    "0x48ab9f29795dfb44b36587c50da4b30c0e84d3ed": "Huobi: Old Address 18",
    "0x90f49e24a9554126f591d28174e157ca267194ba": "Huobi: Old Address 19",
    "0x1b93129f05cc2e840135aab154223c75097b69bf": "Huobi: Old Address 2",
    "0xe3314bbf3334228b257779e28228cfb86fa4261b": "Huobi: Old Address 20",
    "0x6edb9d6547befc3397801c94bb8c97d2e8087e2f": "Huobi: Old Address 21",
    "0x8aabba0077f1565df73e9d15dd3784a2b0033dad": "Huobi: Old Address 22",
    "0xd3a2f775e973c1671f2047e620448b8662dcd3ca": "Huobi: Old Address 23",
    "0x1c515eaa87568c850043a89c2d2c2e8187adb056": "Huobi: Old Address 24",
    "0x60b45f993223dcb8bdf05e3391f7630e5a51d787": "Huobi: Old Address 25",
    "0xa23d7dd4b8a1060344caf18a29b42350852af481": "Huobi: Old Address 26",
    "0x9eebb2815dba2166d8287afa9a2c89336ba9deaa": "Huobi: Old Address 27",
    "0xd10e08325c0e95d59c607a693483680fe5b755b3": "Huobi: Old Address 28",
    "0xc837f51a0efa33f8eca03570e3d01a4b2cf97ffd": "Huobi: Old Address 29",
    "0xeb6d43fe241fb2320b5a3c9be9cdfd4dd8226451": "Huobi: Old Address 3",
    "0xf7a8af16acb302351d7ea26ffc380575b741724c": "Huobi: Old Address 30",
    "0x636b76ae213358b9867591299e5c62b8d014e372": "Huobi: Old Address 31",
    "0x9a755332d874c893111207b0b220ce2615cd036f": "Huobi: Old Address 32",
    "0xecd8b3877d8e7cd0739de18a5b545bc0b3538566": "Huobi: Old Address 33",
    "0xef54f559b5e3b55b783c7bc59850f83514b6149c": "Huobi: Old Address 34",
    "0x956e0dbecc0e873d34a5e39b25f364b2ca036730": "Huobi: Old Address 4",
    "0x6f50c6bff08ec925232937b204b0ae23c488402a": "Huobi: Old Address 5",
    "0xdf95de30cdff4381b69f9e4fa8dddce31a0128df": "Huobi: Old Address 6",
    "0x25c6459e5c5b01694f6453e8961420ccd1edf3b1": "Huobi: Old Address 7",
    "0x04645af26b54bd85dc02ac65054e87362a72cb22": "Huobi: Old Address 8",
    "0xb2a48f542dc56b89b24c04076cbe565b3dc58e7b": "Huobi: Old Address 9",
    "0x6262998ced04146fa42253a5c0af90ca02dfd2a3": "Crypto.com",
    "0x46340b20830761efd32832a74d7169b29feb9758": "Crypto.com 2",
    "0x72a53cdbbcc1b9efa39c834a540550e23463aacb": "Crypto.com 3",
    "0x7758e507850da48cd47df1fb5f875c23e3340c50": "Crypto.com 4",
    "0xcffad3200574698b78f32232aa9d63eabd290703": "Crypto.com 5",
    "0xae45a8240147e6179ec7c9f92c5a18f9a97b3fca": "Crypto.com: Deposit Funder",
    "0x2910543af39aba0cd09dbb2d50200b3e800a63d2": "Kraken",
    "0xae2d4617c862309a3d75a0ffb358c7a5009c673f": "Kraken 10",
    "0x43984d578803891dfa9706bdeee6078d80cfc79e": "Kraken 11",
    "0x66c57bf505a85a74609d2c83e94aabb26d691e1f": "Kraken 12",
    "0xda9dfa130df4de4673b89022ee50ff26f6ea73cf": "Kraken 13",
    "0xa83b11093c858c86321fbc4c20fe82cdbd58e09e": "Kraken 14",
    "0x0a869d79a7052c7f1b55a8ebabbea3420f0d1e13": "Kraken 2",
    "0xe853c56864a2ebe4576a807d26fdc4a0ada51919": "Kraken 3",
    "0x267be1c1d684f78cb4f6a176c4911b741e4ffdc0": "Kraken 4",
    "0xfa52274dd61e1643d2205169732f29114bc240b3": "Kraken 5",
    "0x53d284357ec70ce289d6d64134dfac8e511c8a3d": "Kraken 6",
    "0x89e51fa8ca5d66cd220baed62ed01e8951aa7c40": "Kraken 7",
    "0xc6bed363b30df7f35b601a5547fe56cd31ec63da": "Kraken 8",
    "0x29728d0efd284d85187362faa2d4d76c2cfc2612": "Kraken 9",
    "0x2e7542ec36df6429d8c397f88c4cf0c925948f44": "Kraken: Deployer 1",
    "0xa24787320ede4cc19d800bf87b41ab9539c4da9d": "Kraken: Deployer 2",
    "0xe9f7ecae3a53d2a67105292894676b00d1fab785": "Kraken: Hot Wallet",
    "0xe1498a9ef5c6aa51790a642f70c31238326b1724": "Kraken: MANA Token Wallet",
    "0x2b5634c42055806a59e9107ed44d43c426e58258": "KuCoin",
    "0xcad621da75a66c7a8f4ff86d30a2bf981bfc8fdd": "KuCoin 10",
    "0xec30d02f10353f8efc9601371f56e808751f396f": "KuCoin 11",
    "0x738cf6903e6c4e699d1c2dd9ab8b67fcdb3121ea": "KuCoin 12",
    "0xd89350284c7732163765b23338f2ff27449e0bf5": "KuCoin 13",
    "0x88bd4d3e2997371bceefe8d9386c6b5b4de60346": "KuCoin 14",
    "0xb8e6d31e7b212b2b7250ee9c26c56cebbfbe6b23": "KuCoin 15",
    "0x689c56aef474df92d44a1b70850f808488f9769c": "KuCoin 2",
    "0xa1d8d972560c2f8144af871db508f0b0b10a3fbf": "KuCoin 3",
    "0x4ad64983349c49defe8d7a4686202d24b25d0ce8": "KuCoin 4",
    "0x1692e170361cefd1eb7240ec13d048fd9af6d667": "KuCoin 5",
    "0xd6216fc19db775df9774a6e33526131da7d19a2c": "KuCoin 6",
    "0xe59cd29be3be4461d79c0881d238cbe87d64595a": "Kucoin 7",
    "0x899b5d52671830f567bf43a14684eb14e1f945fe": "KuCoin 8",
    "0xf16e9b0d03470827a95cdfd0cb8a8a3b46969b91": "KuCoin 9",
    "0x2602669a92fccf44e5319ff51b0f453aab9db021": "KuCoin Contract",
    "0xf2b16510270a214130c6b17ff0e9bf87585126bd": "KuCoin: Deployer",
    "0x061f7937b7b2bc7596539959804f86538b6368dc": "KuCoin: Deposit Funder",
    "0xf34960d9d60be18cc1d5afc1a6f012a723a28811": "KuCoin: KCS Token",
    "0x039b5649a59967e3e936d7471f9c3700100ee1ab": "KuCoin: Old KCS Token",
    "0x71660c4005ba85c37ccec55d0c4493e66fe775d3": "Coinbase 1",
    "0xa9d1e08c7793af67e9d92fe308d5697fb81d3e43": "Coinbase 10",
    "0x77696bb39917c91a0c3908d577d5e322095425ca": "Coinbase 11",
    "0x7c195d981abfdc3ddecd2ca0fed0958430488e34": "Coinbase 12",
    "0x95a9bd206ae52c4ba8eecfc93d18eacdd41c88cc": "Coinbase 13",
    "0xb739d0895772dbb71a89a3754a160269068f0d45": "Coinbase 14",
    "0x503828976d22510aad0201ac7ec88293211d23da": "Coinbase 2",
    "0xddfabcdc4d8ffc6d5beaf154f18b778f892a0740": "Coinbase 3",
    "0x3cd751e6b0078be393132286c442345e5dc49699": "Coinbase 4",
    "0xb5d85cbf7cb3ee0d56b3bb207d5fc4b82f43f511": "Coinbase 5",
    "0xeb2629a2734e272bcc07bda959863f316f4bd4cf": "Coinbase 6",
    "0xd688aea8f7d450909ade10c47faa95707b0682d9": "Coinbase 7",
    "0x02466e547bfdab679fc49e96bbfc62b9747d997c": "Coinbase 8",
    "0x6b76f8b1e9e59913bfe758821887311ba1805cab": "Coinbase 9",
    "0xbe3c68821d585cf1552214897a1c091014b1eb0a": "Coinbase: Account Blacklister",
    "0xf6874c88757721a02f47592140905c4336dfbc61": "Coinbase: Coinbase Commerce",
    "0x881d4032abe4188e2237efcd27ab435e81fc6bb1": "Coinbase: Commerce",
    "0x6c8dd0e9cc58c07429e065178d88444b60e60b80": "Coinbase: Commerce Fee",
    "0xbc8ec259e3026ae0d87bc442d034d6882ce4a35c": "Coinbase: Commerce Fee 2",
    "0x02d24cab4f2c3bf6e6eb07ea07e45f96baccffe7": "Coinbase: Commerce Fee 3",
    "0xce352e98934499be70f641353f16a47d9e1e3abd": "Coinbase: Commerce Fee 4",
    "0x90e18a6920985dbacc3d76cf27a3f2131923c720": "Coinbase: Commerce Fee 5",
    "0x4b23d52eff7c67f5992c2ab6d3f69b13a6a33561": "Coinbase: Commerce Fee 6",
    "0xd2276af80582cac230edc4c42e9a9c096f3c09aa": "Coinbase: Deposit 1",
    "0xa090e606e30bd747d4e6245a1517ebe430f0057e": "Coinbase: Miscellaneous"
}

Vue.filter("ExchangeChecker", function (value) {
    if (value in exchangeAddresses) {
        return true
    } else {
        return false
    }
})

Vue.filter('HideValueFormatter', function (value) {
    if (!loggedUserStore.state.visibility) {
        return "***";
    } else {
        return value;
    }
})

Vue.filter('CurrencyFormatter', function (value, currency) {
    if (currency == null) {
        currency = ""
    }
    let decimals = getCurrencyDecimalsFromString(currency)
    let symbol = getCurrencySymbolFromString(currency)
    if (!loggedUserStore.state.visibility) {
        return "***" + " " + truncateString(symbol, 10)
    }
    if (!value) {
        value = 0
    }
    if (!decimals) {
        decimals = 0
    }
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = i18n.n(value, "decimal") + getSpaceFromCurrencySymbol(symbol) + truncateString(symbol, 10)

    return value
})

Vue.filter('UnhideCurrencyFormatter', function (value, currency) {
    let decimals = getCurrencyDecimalsFromString(currency)
    let symbol = getCurrencySymbolFromString(currency)
    if (!value) {
        value = 0
    }
    if (!decimals) {
        decimals = 0
    }
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = i18n.n(value, "decimal") + getSpaceFromCurrencySymbol(symbol) + truncateString(symbol, 10)

    return value
})

Vue.filter('BlockchainCoinFormatter', function (value, blockchainType) {
    let currency = getCurrencyFromBlockchainType(blockchainType)
    let decimals = getCurrencyDecimalsFromString(currency)
    let symbol = getCurrencySymbolFromString(currency)
    if (!value) {
        value = 0
    }
    if (!decimals) {
        decimals = 0
    }
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = i18n.n(value, "decimal") + getSpaceFromCurrencySymbol(symbol) + symbol
    return value
})

Vue.filter('PercentageFormatter', function (value) {
    if (!value) {
        value = 0
    }
    value = i18n.n(value, "percent")
    return value
})

Vue.filter('RoundPriceFormatter', function (value, currency) {
    if (!value) {
        value = 0
    }
    let symbol = getCurrencySymbolFromString(currency)
    value = Number(value.toPrecision(5))
    return i18n.n(value, "decimal") + getSpaceFromCurrencySymbol(symbol) + symbol
})

Vue.filter('RoundFormatter', function (value, decimals) {
    if (!value) {
        value = 0
    }
    if (!decimals) {
        decimals = 0
    }
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    return i18n.n(value, "decimal")
})

Vue.filter('TruncateStringFormatter', function (value, n) {
    return truncateString(value, n);
})

Vue.filter('LastDateFormatter', function (date) {
    return moment(date).fromNow()
})

Vue.filter('DateFormatter', function (date) {
    return moment(date).format("LLL")
})

Vue.filter('OnlyDateFormatter', function (date) {
    return moment(date).format("LL")
})

Vue.filter("YesNoFormatter", function (value) {
    if (value === true) {
        return i18n.t("label.yes")
    } else if (value === false) {
        return i18n.t("label.no")
    } else {
        return "-"
    }
})

Vue.filter('PersonalAddressFormatter', function (address) {
    let length = address.length
    if (userAddressesStore.state.addresses.length > 0) {
        let targetAddresses = userAddressesStore.state.addresses.filter(userAddress => userAddress.address.address === address);
        if (targetAddresses.length > 0) {
            let userAddress = targetAddresses[0]
            return userAddress.name;
        }
    }
    // Check if address is present in exchangeFormatter object
    if (exchangeAddresses[address]) {
        return exchangeAddresses[address]
    }
    return address.substring(0, 6) + "..." + address.substring(length - 4, length + 1)
})

Vue.filter('AddressFormatter', function (address) {
    if (address && address.length > 0) {
        let length = address.length
        if (length > 10) {
            return address.substring(0, 6) + "..." + address.substring(length - 4, length + 1)
        } else {
            return address
        }
    } else {
        return null
    }

})

Vue.filter('ExternalTierFormatter', function (value) {
    let string
    switch (value) {
        case "BASE":
            string = "Base"
            break
        case "BRONZE":
            string = "Club Bronze"
            break
        case "SILVER":
            string = "Club Silver"
            break
        case "GOLD":
            string = "Club Gold"
            break
        case "PLATINUM":
            string = "Club Platinum"
            break
        default:
            string = value
            break               
    }
    return string
})

Vue.filter('NftEvaluationMethodTranslator', function (value) {
    let string
    switch (value) {
        case "FLOOR":
            string = i18n.t('label.floorValue')
            break
        case "COST":
            string = i18n.t('label.costValue')
            break
    }
    return string
})

Vue.filter("TaxDocumentTypeFormatter", function (value) {
    let string
    switch (value) {
        case "RW":
            string = i18n.t("tax.rwModule")
            break
        case "RT":
            string = i18n.t("tax.rtModule")
            break
        case "OTHER_FINANCIAL_INCOME":
            string = i18n.t("tax.otherFinancialIncome")
            break
        case "RELEVANT_TRANSACTIONS":
            string = i18n.t("tax.relevantTransactions")
            break
        case "FIAT_TRANSACTIONS":
            string = i18n.t("tax.fiatTransactions")
            break
        case "FIRST_BALANCE":
            string = i18n.t("tax.firstBalanceModule")
            break
        case "LAST_BALANCE":
            string = i18n.t("tax.lastBalanceModule")
            break
        case "PLATFORM_BALANCE":
            string = i18n.t("tax.platformBalanceModule")
            break    
        case "SUMMARY":
            string = i18n.t("tax.summaryModule")
            break   
        case "DAILY_STOCK":
            string = i18n.t("tax.dailyStockModule")
            break
        case "TAX_REPORT":
            string = i18n.t("tax.taxReport")
            break
        case "NOTES":
            string = i18n.t("tax.notesModule")
            break
        default:
            string = value
            break
    }
    return string;
})

Vue.filter("TaxDocumentTypeDescriptionFormatter", function (value) {
    let string
    switch (value) {
        case "RW":
            string = i18n.t("tax.rwModuleDescription")
            break
        case "RT":
            string = i18n.t("tax.rtModuleDescription")
            break
        case "OTHER_FINANCIAL_INCOME":
            string = i18n.t("tax.otherFinancialIncomeDescription")
            break
        case "RELEVANT_TRANSACTIONS":
            string = i18n.t("tax.relevantTransactionsDescription")
            break
        case "FIAT_TRANSACTIONS":
            string = i18n.t("tax.fiatTransactionsDescription")
            break
        case "FIRST_BALANCE":
            string = i18n.t("tax.firstBalanceModuleDescription")
            break
        case "LAST_BALANCE":
            string = i18n.t("tax.lastBalanceModuleDescription")
            break
        case "PLATFORM_BALANCE":
            string = i18n.t("tax.platformBalanceModuleDescription")
            break  
        case "SUMMARY":
            string = i18n.t("tax.summaryModuleDescription")
            break     
        case "DAILY_STOCK":
            string = i18n.t("tax.dailyStockModuleDescription")
            break
        case "TAX_REPORT":
            string = i18n.t("tax.taxReportDescription")
            break
        case "NOTES":
            string = i18n.t("tax.notesModuleDescription")
            break
        default:
            string = value
            break
    }
    return string;
})

Vue.filter("FinancialDocumentTypeFormatter", function (value) {
    let string
    switch (value) {
        case "CURRENT_BALANCE":
            string = i18n.t("financial.currentBalance")
            break
        case "FEES":
            string = i18n.t("financial.fees")
            break
        case "TRANSACTION_LIST":
            string = i18n.t("financial.transactionList")
            break
        default:
            string = value
            break
    }
    return string;
})

Vue.filter("DocumentStatusFormatter", function (value) {
    let string
    switch (value) {
        case "CREATING":
            string = i18n.t("document.creating")
            break
        case "UP_TO_DATE":
            string = i18n.t("document.upToDate")
            break
        case "PENDING":
            string = i18n.t("document.pending")
            break
        case "PROCESSING":
            string = i18n.t("document.processing")
            break
        case "MANUAL_APPROVAL":
            string = i18n.t("document.manualApproval")
            break
        case "ERROR":
            string = i18n.t("document.error")
            break
        case "WARNING":
            string = i18n.t("document.success")
            break
        case "SUCCESS":
            string = i18n.t("document.success")
            break
        case "TO_BE_UPDATED":
            string = i18n.t("document.toBeUpdated")
            break
        case "OUTDATED":
            string = i18n.t("document.outdated")
            break
        default:
            string = value
            break
    }
    return string;
})

Vue.filter("ExternalDataErrorTypeFormatter", function (value) {
    let string
    switch (value) {
        case "CSV_PARSING_ERROR":
            string = i18n.t("externalData.csvParsingError")
            break
        case "DATE_PARSING_ERROR":
            string = i18n.t("externalData.dateParsingError")
            break
        case "RECEIVED_AMOUNT_PARSING_ERROR":
            string = i18n.t("externalData.receivedAmountParsingError")
            break
        case "SENT_AMOUNT_PARSING_ERROR":
            string = i18n.t("externalData.sentAmountParsingError")
            break
        case "FEE_AMOUNT_PARSING_ERROR":
            string = i18n.t("externalData.feeAmountParsingError")
            break
        case "NO_SENT_OR_RECEIVED_AMOUNT":
            string = i18n.t("externalData.noSentOrReceivedAmount")
            break
        case "SENT_AMOUNT_AND_CURRENCY_MUST_BE_BOTH_PRESENT_OR_BOTH_ABSENT":
            string = i18n.t("externalData.sentAmountAndCurrencyMustBeBothPresentOrBothAbsent")
            break
        case "RECEIVED_AMOUNT_AND_CURRENCY_MUST_BE_BOTH_PRESENT_OR_BOTH_ABSENT":
            string = i18n.t("externalData.receivedAmountAndCurrencyMustBeBothPresentOrBothAbsent")
            break
        case "FEE_AMOUNT_AND_CURRENCY_MUST_BE_BOTH_PRESENT_OR_BOTH_ABSENT":
            string = i18n.t("externalData.feeAmountAndCurrencyMustBeBothPresentOrBothAbsent")
            break
        case "SENT_AMOUNT_MUST_BE_POSITIVE":
            string = i18n.t("externalData.sentAmountMustBePositive")
            break
        case "RECEIVED_AMOUNT_MUST_BE_POSITIVE":
            string = i18n.t("externalData.receivedAmountMustBePositive")
            break
        case "FEE_AMOUNT_MUST_BE_POSITIVE":
            string = i18n.t("externalData.feeAmountMustBePositive")
            break
        case "DATE_IS_MISSING":
            string = i18n.t("externalData.dateIsMissing")
            break
        case "DATE_IS_NOT_IN_THE_PAST":
            string = i18n.t("externalData.dateIsNotInThePast")
            break
        case "NO_TRANSACTIONS":
            string = i18n.t("externalData.noTransactions")
            break
        case "COINBASE_PRO_NOT_UPLOADED":
            string = i18n.t("externalData.coinbaseProNotUploaded")
            break
        case "COINBASE_NOT_EUR_CURRENCY":
            string = i18n.t("externalData.coinbaseNotEurCurrency")
            break
        case "NO_VALID_CSV_FILE_FOUND":
            string = i18n.t("externalData.noValidCsvFound")
            break
        case "MISSING_PREVIOUS_TRANSACTIONS":
            string = i18n.t("externalData.missingPreviousTransactions")
            break
        case "CRYPTOCOM_FILES_MISSING":
            string = i18n.t("externalData.cryptocomFilesMissing")
            break
        case "CRYPTOCOM_EXCHANGE_ARCHIVE_NOT_UPLOADED":
            string = i18n.t("externalData.cryptocomExchangeArchiveNotUploaded")
            break        
        case "CRYPTOCOM_EXCHANGE_DEPOSIT_AND_WITHDRAWALS_NOT_UPLOADED":
            string = i18n.t("externalData.cryptocomExchangeDepositAndWithdrawalsNotUploaded")
            break
        case "YOUNG_PLATFORM_LEDGER_NOT_UPLOADED":
            string = i18n.t("externalData.youngPlatformLedgerNotUploaded")
            break
        case "YOUNG_PLATFORM_TRADES_NOT_UPLOADED":
            string = i18n.t("externalData.youngPlatformTradesNotUploaded")
            break
        case "MANUAL_ERROR":
            string = i18n.t("externalData.manualError")
            break      
        default:
            string = value
            break
    }
    return string;
})
Vue.filter('NftSchemaFormatter', function (value) {
    let string
    switch (value) {
        case "ERC1155":
            string = "ERC1155"
            break
        case "ERC721":
            string = "ERC721"
            break
        case "UNKNOWN":
            string = i18n.t('label.unknown')
    }
    return string
})

Vue.filter('RarityTierFormatter', function (value) {
    let string
    if (value <= 1) {
        return "Top 1%"
    } else if (value <= 5) {
        return "Top 5%"
    } else if (value <= 10) {
        return "Top 10%"
    } else if (value <= 25) {
        return "Top 25%"
    } else if (value <= 50) {
        return "Top 50%"
    } else if (value <= 75) {
        return "Top 75%"
    } else if (value <= 100) {
        return "Floor"
    }
    return string
})

Vue.filter('CountryTypeFormatter', function (value) {
    let string
    switch (value) {
        case "ITALY":
            string = i18n.t('country.italy')
            break
        case "REST_OF_THE_WORLD":
            string = i18n.t('country.restOfTheWorld');
            break
        default:
            string = value
            break
    }
    return string
})

Vue.filter('MarketplaceTypeFormatter', function (value) {
    let string
    switch (value) {
        case "OPENSEA":
            string = "Opensea"
            break
        case "LOOKSRARE":
            string = "LooksRare"
            break
        default:
            string = value
            break
    }
    return string
})

Vue.filter('CostBasisMethodFormatter', function (value) {
    let string
    switch (value) {
        case "LIFO":
            string = "LIFO"
            break
        case "FIFO":
            string = "FIFO"
            break
        case "AVERAGE_COST":
            string = i18n.t('tax.costBasisMethodAverageCost')
            break
    }
    return string
})

Vue.filter('PricingPlanFormatter', function (value) {
    let string
    switch (value) {
        case "FREE":
            string = "Free"
            break
        case "BASE":
            string = "Base"
            break
        case "STANDARD":
            string = "Standard"
            break    
        case "PREMIUM":
        case "PREMIUM_LEGACY":    
            string = "Premium"
            break
        case "YOUNG_PLATFORM":
            string = "Young Platform"
            break    
        case "ENTERPRISE":
            string = i18n.t('plan.enterprise')
            break    
    }
    return string
})

Vue.filter('YoungPricingPlanFormatter', function (value) {
    let string
    switch (value) {
        case "FREE":
            string = "Free"
            break
        case "BASE":
            string = "Base"
            break
        case "BRONZE":
            string = "Bronze"
            break    
        case "SILVER":
            string = "Silver"
            break  
        case "GOLD":
            string = "Gold"
            break  
        case "PLATINUM":
            string = "Platinum"
            break  
        case "ENTERPRISE":
            string = i18n.t('plan.enterprise')
            break    
    }
    return string
})

Vue.filter('PricingPlanDescription', function (value) {
    let string
    switch (value) {
        case "FREE":
            string = i18n.t('plan.freeDescription')
            break
        case "BASE":
            string = i18n.t('plan.startDescription')
            break
        case "STANDARD":
            string = i18n.t('plan.standardDescription')
            break    
        case "PREMIUM":
        case "PREMIUM_LEGACY":    
            string = i18n.t('plan.premiumDescription')
            break
        case "ENTERPRISE":
            string = i18n.t('plan.unlimitedDescription')
            break
        case "YOUNG_PLATFORM":
            string = i18n.t('plan.youngPlatformDescription')
            break    
    }
    return string
})

Vue.filter('InterestFormatter', function (value) {
    let string
    switch (value) {
        case "INTEREST":
            string = i18n.t('tool.interest')
            break
        case "CAPITAL":
            string = i18n.t('tool.capital')
            break
    }
    return string
})

Vue.filter('TransactionScanUrlFormatter', function (transactionHash, blockchainType) {
    let string
    let baseUrl = getScanBaseUrlFromBlockchainType(blockchainType)
    // BLOCKCHAIN_TYPE: Add here all supported blockchain types
    switch (blockchainType) {
        case "ETHEREUM":
        case "POLYGON":
        case "BSC":
        case "AVALANCHE":
        case "ARBITRUM":
        case "FANTOM":
        case "AURORA":
        case "HECO":
        case "MOONRIVER":
        case "MOONBEAM":
        case "KLAYTN":
        case "OPTIMISM":
        case "XDAI":
        case "CELO":
        case "HARMONY":
        case "BOBA":
        case "CLV":
        case "BLAST":
        case "BASE":
        case "ARBITRUM_NOVA":
        case "POLYGONZK":
        case "ZKSYNC":                    
            string = baseUrl + "tx/" + transactionHash
            break
        case "BITCOIN":
            string = baseUrl + "explorer/transactions/btc/" + transactionHash
            break 
        case "CRONOS":
            if (!transactionHash.startsWith("0x")) {
                baseUrl = getScanBaseUrlFromBlockchainType("CRYPTOORG")
            }
            string = baseUrl + "tx/" + transactionHash
            break
        default:
            string = ""
            break
    }
    return string
})

Vue.filter('AddressScanUrlFormatter', function (address, blockchainType) {
    let string
    const baseUrl = getScanBaseUrlFromBlockchainType(blockchainType)
    // BLOCKCHAIN_TYPE: Add here all supported blockchain types
    switch (blockchainType) {
        case "ETHEREUM":
        case "POLYGON":
        case "BSC":
        case "AVALANCHE":
        case "ARBITRUM":
        case "FANTOM":
        case "AURORA":
        case "HECO":
        case "MOONRIVER":
        case "MOONBEAM":
        case "KLAYTN":
        case "CRONOS":
        case "OPTIMISM":
        case "XDAI":
        case "CELO":
        case "HARMONY":
        case "BOBA":
        case "CLV":
        case "BLAST":
        case "BASE":
        case "ARBITRUM_NOVA":
        case "POLYGONZK":
        case "ZKSYNC":  
            string = baseUrl + "address/" + address
            break
        case "BITCOIN":
            string = baseUrl + "explorer/addresses/btc/" + address
            break
        default:
            string = ""
            break
    }
    return string
})

Vue.filter('AssetTypeFormatter', function (value) {
    let string
    switch (value) {
        case "COIN":
            string = "COIN"
            break
        case "DEFI":
            string = "DEFI"
            break
        case "NFT":
            string = "NFT"
            break
    }
    return string
})

Vue.filter('UserAddressTypeFormatter', function (value) {
    let string
    switch (value) {
        case "PERSONAL":
            string = i18n.t('label.personal')
            break
        case "WATCHLIST":
            string = i18n.t('label.watchlist')
            break
    }
    return string
})

Vue.filter('LanguageTypeFormatter', function (value) {
    let string
    switch (value) {
        case "IT":
            string = 'Italiano'
            break
        case "EN":
            string = 'English'
            break
    }
    return string
})

Vue.filter('NftEvaluationMethodTypeFormatter', function (value) {
    let string
    switch (value) {
        case "FLOOR":
            string = i18n.t('label.floor')
            break
        case "COST":
            string = i18n.t('label.cost')
            break
    }
    return string
})

Vue.filter('BlockchainTypeFormatter', function (value) {
    if (value == 'GENERIC') {
        return i18n.t('label.genericSource')
    }
    return getBlockchainTypeFromString(value)
})

Vue.filter('WalletTypeFormatter', function (value) {
    return getWalletTypeFromString(value)
})

Vue.filter('PlatformTypeFormatter', function (value) {
    return getPlatformTypeFromString(value)
})

Vue.filter('NewsFeedTypeFormatter', function (value) {
    return getNewsFeedTypeFromString(value)
})

Vue.filter('TransactionActionTypeStringFormatter', function (value) {
    let groupedTransactionActionType = getGroupValueFromTransactionActionType(value)
    let string
    switch (groupedTransactionActionType) {
        case "ERROR":
            string = i18n.t('transactionType.error')
            break
        case "CONTRACT_INTERACTION":
            string = i18n.t('transactionType.contractInteraction')
            break
        case "SELF":
            string = i18n.t('transactionType.self')
            break
        case "SEND":
            string = i18n.t('transactionType.send')
            break
        case "DEPOSIT":
            string = i18n.t('transactionType.deposit')
            break
        case "WITHDRAW":
            string = i18n.t('transactionType.withdraw')
            break
        case "STAKE":
            string = i18n.t('transactionType.stake')
            break
        case "UNSTAKE":
            string = i18n.t('transactionType.unstake')
            break
        case "BRIDGE_DEPOSIT":
            string = i18n.t('transactionType.bridgeIn')
            break
        case "BRIDGE_WITHDRAW":
            string = i18n.t('transactionType.bridgeOut')
            break
        case "RECEIVE":
            string = i18n.t('transactionType.receive')
            break
        case "WRAP":
            string = i18n.t('transactionType.wrap')
            break
        case "UNWRAP":
            string = i18n.t('transactionType.unwrap')
            break
        case "BURN":
            string = i18n.t('transactionType.burn')
            break
        case "CLAIM":
            string = i18n.t('transactionType.claim')
            break
        case "AIRDROP":
            string = i18n.t('transactionType.airdrop')
            break
        case "REWARD":
            string = i18n.t('transactionType.reward')
            break
        case "MINT":
            string = i18n.t('transactionType.mint')
            break
        case "BUY":
            string = i18n.t('transactionType.buy')
            break
        case "SELL":
            string = i18n.t('transactionType.sell')
            break
        case "SWAP":
            string = i18n.t('transactionType.swap')
            break
        case "ADD_LIQUIDITY":
            string = i18n.t('transactionType.addLiquidity')
            break
        case "REMOVE_LIQUIDITY":
            string = i18n.t('transactionType.removeLiquidity')
            break
        case "UNIDENTIFIED":
            string = i18n.t('transactionType.unidentified')
            break
        case "PAYMENT":
            string = i18n.t('transactionType.payment')
            break
        case "PAYMENT_REFUND":
            string = i18n.t('transactionType.paymentRefund')
            break
        case "CASHBACK":
            string = i18n.t('transactionType.cashback')
            break
        case "CASHBACK_REVERTED":
            string = i18n.t('transactionType.cashbackReverted')
            break
        case "REIMBURSEMENT":
            string = i18n.t('transactionType.reimbursement')
            break
        case "REIMBURSEMENT_REVERTED":
            string = i18n.t('transactionType.reimbursementReverted')
            break
        case "REPAY_CREDIT":
            string = i18n.t('transactionType.repayCredit')
            break    
        case "INTEREST":
            string = i18n.t('transactionType.interest')
            break
        case "FEE":
            string = i18n.t('transactionType.fee')
            break
        case "MARGIN_GAIN":
            string = i18n.t('transactionType.marginGain')
            break
        case "MARGIN_LOSS":
            string = i18n.t('transactionType.marginLoss')
            break
        case "BORROW":
            string = i18n.t('transactionType.borrow')
            break
        case "COLLATERAL_ADDED":
            string = i18n.t('transactionType.collateralAdded')
            break
        case "COLLATERAL_RETURNED":
            string = i18n.t('transactionType.collateralReturned')
            break
        case "FUTURE_LOSS":
            string = i18n.t('transactionType.futureLoss')
            break
        case "FUTURE_PROFIT":
            string = i18n.t('transactionType.futureProfit')
            break
        case "REALIZED_LOSS":
            string = i18n.t('transactionType.realizedLoss')
            break
        case "REALIZED_PROFIT":
            string = i18n.t('transactionType.realizedProfit')
            break
        case "LEND":
            string = i18n.t('transactionType.lend')
            break
        case "UNLEND":
            string = i18n.t('transactionType.unlend')
            break
        case "DONATION":
            string = i18n.t('transactionType.donation')
            break
        case "LOST":
            string = i18n.t('transactionType.lost')
            break
        case "GIFT":
            string = i18n.t('transactionType.gift')
            break
        case "REPAY_LOAN":
            string = i18n.t('transactionType.repayLoan')
            break
        case "MINING":
            string = i18n.t('transactionType.mining')
            break
        case "NOT_TAXABLE_EVENT":
            string = i18n.t('transactionType.notTaxableEvent')
            break                                                                
        default:
            string = value
            break
    }
    return string
})

Vue.filter('TransactionActionTypeIconFormatter', function (value) {
    let groupedTransactionActionType = getGroupValueFromTransactionActionType(value)
    let string
    switch (groupedTransactionActionType) {
        case "ERROR":
            string = 'mdi-alert'
            break
        case "CONTRACT_INTERACTION":
            string = 'mdi-cog'
            break
        case "SELF":
            string = 'mdi-account'
            break
        case "SEND":
            string = 'mdi-arrow-right'
            break
        case "DEPOSIT":
            string = 'mdi-arrow-down'
            break
        case "WITHDRAW":
            string = 'mdi-arrow-up'
            break
        case "STAKE":
            string = 'mdi-database-arrow-right'
            break
        case "UNSTAKE":
            string = 'mdi-database-arrow-left'
            break
        case "BRIDGE_DEPOSIT":
            string = 'mdi-boom-gate-arrow-up'
            break
        case "BRIDGE_WITHDRAW":
            string = 'mdi-boom-gate-arrow-down'
            break
        case "RECEIVE":
            string = 'mdi-arrow-left'
            break
        case "WRAP":
            string = 'mdi-autorenew'
            break
        case "UNWRAP":
            string = 'mdi-autorenew-off'
            break
        case "BURN":
            string = 'mdi-fire'
            break
        case "CLAIM":
            string = 'mdi-database-plus'
            break
        case "AIRDROP":
            string = 'mdi-gift'
            break
        case "REWARD":
            string = 'mdi-trophy'
            break
        case "MINT":
            string = 'mdi-package-variant-plus'
            break
        case "BUY":
            string = 'mdi-tag-arrow-left'
            break
        case "SELL":
            string = 'mdi-tag-arrow-right'
            break
        case "SWAP":
            string = 'mdi-swap-horizontal'
            break
        case "ADD_LIQUIDITY":
            string = 'mdi-archive-arrow-up'
            break
        case "REMOVE_LIQUIDITY":
            string = 'mdi-archive-arrow-down'
            break
        case "UNIDENTIFIED":
            string = 'mdi-help-rhombus'
            break
        case "PAYMENT":
            string = "mdi-credit-card"
            break
        case "PAYMENT_REFUND":
            string = "mdi-cash-refund"
            break
        case "CASHBACK":
            string = "mdi-cash-plus"
            break
        case "CASHBACK_REVERTED":
            string = "mdi-cash-minus"
            break
        case "REIMBURSEMENT":
            string = "mdi-cash-check"
            break
        case "REIMBURSEMENT_REVERTED":
            string = "mdi-cash-remove"
            break
        case "REPAY_CREDIT":
            string = "mdi-cash-sync"
            break
        case "FEE":
            string = "mdi-sack"
            break
        case "MARGIN_GAIN":
            string = "mdi-margin"
            break
        case "MARGIN_LOSS":
            string = "mdi-sack-percent"
            break
        case "FUTURE_PROFIT":
            string = "mdi-margin"
            break
        case "FUTURE_LOSS":
            string = "mdi-sack-percent"
            break
        case "REALIZED_PROFIT":
            string = "mdi-margin"
            break
        case "REALIZED_LOSS":
            string = "mdi-sack-percent"
            break                 
        case "INTEREST":
            string = "mdi-percent-box"
            break                             
        case "BORROW":
            string = "mdi-bank-transfer-in"
            break
        case "COLLATERAL_ADDED":
            string = "mdi-bank-transfer-in"
            break
        case "COLLATERAL_RETURNED":
            string = "mdi-bank-transfer-out"
            break        
        case "LEND":
            string = "mdi-bank-transfer-out"
            break
        case "UNLEND":
            string = "mdi-bank-minus"
            break
        case "DONATION":
            string = "mdi-hand-coin"
            break
        case "LOST":
            string = "mdi-alert-octagram"
            break
        case "GIFT":
            string = "mdi-wallet-giftcard"
            break
        case "REPAY_LOAN":
            string = "mdi-bank-plus"
            break
        case "MINING":
            string = "mdi-pickaxe"
            break
        case "NOT_TAXABLE_EVENT":
            string = "mdi-scale-balance"
            break 
        default:
            string = ''
    }
    return string
})

Vue.filter('BlockchainTypeListFormatter', function (blockchainTypes) {
    let string
    if (!blockchainTypes) {
        string = i18n.t('misc.allBlockchainType')
    } else {
        const totalBlockchainTypes = blockchainTypes.length
        if (totalBlockchainTypes == 0) {
            string = i18n.t('misc.allBlockchainType')
        } else if (totalBlockchainTypes > 1 && totalBlockchainTypes < Vue.prototype.$SUPPORTED_BLOCKCHAINS.length + Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = i18n.t('misc.multipleBlockchainType') + " (" + totalBlockchainTypes + ")"
        } else if (totalBlockchainTypes == Vue.prototype.$SUPPORTED_BLOCKCHAINS.length + Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = i18n.t('misc.allBlockchainType')
        } else if (totalBlockchainTypes > Vue.prototype.$SUPPORTED_BLOCKCHAINS.length + Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = "ERROR"
        } else {
            string = getBlockchainTypeFromString(blockchainTypes[0])
        }
    }
    return string
})

Vue.filter('ExchangeTypeListFormatter', function (blockchainTypes) {
    let string
    if (!blockchainTypes) {
        string = i18n.t('misc.allExchangeType')
    } else {
        const totalBlockchainTypes = blockchainTypes.length
        if (totalBlockchainTypes == 0) {
            string = i18n.t('misc.allExchangeType')
        } else if (totalBlockchainTypes > 1 && totalBlockchainTypes < Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = i18n.t('misc.multipleExchangeType') + " (" + totalBlockchainTypes + ")"
        } else if (totalBlockchainTypes == Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = i18n.t('misc.allExchangeType')
        } else if (totalBlockchainTypes > Vue.prototype.$SUPPORTED_EXCHANGES.length) {
            string = "ERROR"
        } else {
            string = getBlockchainTypeFromString(blockchainTypes[0])
        }
    }
    return string
})

Vue.filter('NewsFeedTypeListFormatter', function (newsFeedTypes) {
    let string
    if (!newsFeedTypes) {
        string = i18n.t('misc.allNewsFeedType')
    } else {
        const totalNewsFeedTypes = newsFeedTypes.length
        if (totalNewsFeedTypes == 0) {
            string = i18n.t('misc.allNewsFeedType')
        } else if (totalNewsFeedTypes > 1 && totalNewsFeedTypes < Vue.prototype.$SUPPORTED_FEEDS.length) {
            string = i18n.t('misc.multipleNewsFeedType') + " (" + totalNewsFeedTypes + ")"
        } else if (totalNewsFeedTypes == Vue.prototype.$SUPPORTED_FEEDS.length) {
            string = i18n.t('misc.allNewsFeedType')
        } else if (totalNewsFeedTypes > Vue.prototype.$SUPPORTED_FEEDS.length) {
            string = "ERROR"
        } else {
            string = getNewsFeedTypeFromString(newsFeedTypes[0])
        }
    }
    return string
})

Vue.filter('AddressListFormatter', function (selectedAddresses, totalAddresses) {
    let string
    if (!selectedAddresses) {
        string = i18n.t('misc.allAddresses')
    } else {
        const totalSelectedAddresses = selectedAddresses.length
        if (totalSelectedAddresses == 0) {
            string = i18n.t('misc.allAddresses')
        } else if (totalSelectedAddresses > 1 && totalSelectedAddresses < totalAddresses) {
            string = i18n.t('misc.multipleAddresses') + " (" + totalSelectedAddresses + ")"
        } else if (totalSelectedAddresses == totalAddresses) {
            string = i18n.t('misc.allAddresses')
        } else if (totalSelectedAddresses > totalAddresses) {
            string = "ERROR"
        } else {
            string = selectedAddresses[0].name
        }
    }
    return string
})

Vue.filter('TagListFormatter', function (selectedTags, totalTags) {
    let string
    if (!selectedTags) {
        string = i18n.t('misc.allTags')
    } else {
        const totalSelectedTags = selectedTags.length
        if (totalSelectedTags == 0) {
            string = i18n.t('misc.allTags')
        } else if (totalSelectedTags > 1 && totalSelectedTags < totalTags) {
            string = i18n.t('misc.multipleTags') + " (" + totalSelectedTags + ")"
        } else if (totalSelectedTags == totalTags) {
            string = i18n.t('misc.allTags')
        } else if (totalSelectedTags > totalTags) {
            string = "ERROR"
        } else {
            string = selectedTags[0]
        }
    }
    return string
})

Vue.filter('TransactionTypeListFormatter', function (selectedTransactionTypes, totalTransactionTypes) {
    let string
    if (!selectedTransactionTypes) {
        string = i18n.t('misc.allTransactionTypes')
    } else {
        const totalSelectedTransactionTypes = selectedTransactionTypes.length
        if (totalSelectedTransactionTypes == 0) {
            string = i18n.t('misc.allTransactionTypes')
        } else if (totalSelectedTransactionTypes > 1 && totalSelectedTransactionTypes < totalTransactionTypes) {
            string = i18n.t('misc.multipleTransactionTypes') + " (" + totalSelectedTransactionTypes + ")"
        } else if (totalSelectedTransactionTypes == totalTransactionTypes) {
            string = i18n.t('misc.allTransactionTypes')
        } else if (totalSelectedTransactionTypes > totalTransactionTypes) {
            string = "ERROR"
        } else {
            string = selectedTransactionTypes[0]
        }
    }
    return string
})

Vue.filter('CurrencyFromCountryFilter', function (country) {
    let string
    switch (country) {
        case "Austria":
        case "Belgium":
        case "Bulgaria":
        case "Croatia":
        case "Cyprus":
        case "Czech Republic":
        case "Denmark":
        case "Estonia":
        case "Finland":
        case "France":
        case "Germany":
        case "Greece":
        case "Ireland":
        case "Italy":
        case "Latvia":
        case "Lithuania":
        case "Luxembourg":
        case "Malta":
        case "Netherlands":
        case "Poland":
        case "Portugal":
        case "Slovakia":
        case "Slovenia":
        case "Spain":
        case "Sweden":
            string = "EUR"
            break
        case "United Kingdom":
            string = "GBP"
            break
        case "Switzerland":
            string = "CHF"
            break
        default:
            string = "USD"
            break
    }
    return string
})
