<template>
    <v-menu offset-y rounded right open-on-click width="800px">
        <template v-slot:activator="{ on, attrs }">
            <v-badge :content="unreadCount" :value="unreadCount > 0" color="error" overlap right>
                <v-icon v-on="on" v-bind="attrs" color="white" @click="readUnreadNotifications">mdi-bell-circle
                </v-icon>
            </v-badge>
        </template>
        <v-card v-if="webNotifications.length > 0 && $vuetify.breakpoint.smAndUp">
            <v-timeline dense reverse>
                <v-timeline-item v-for="webNotification in webNotifications" :key="webNotification.created" small
                    fill-dot color="accent">
                    <template v-slot:icon>
                        <v-icon @click="reloadIfCurrentPath(webNotification.url)" color="white" small v-if="webNotification.url">
                            mdi-share
                        </v-icon>
                        <v-icon v-else color="white">
                            mdi-circle-small
                        </v-icon>
                    </template>
                    <div class="px-5">
                        <div>
                            <v-icon small color="error" v-if="!webNotification.read" class="mr-1">mdi-alert-decagram
                            </v-icon>
                            <strong :class="isErrorClass(webNotification.notificationType)">{{ webNotification.title }}</strong>
                        </div>
                        <div>
                             <small>@{{ webNotification.created | DateFormatter }}</small>
                        </div>
                        <small>{{ webNotification.body }}</small>
                    </div>
                </v-timeline-item>
            </v-timeline>
        </v-card>
        <v-card v-if="webNotifications.length > 0 && $vuetify.breakpoint.xs">
            <v-timeline dense reverse>
                <v-timeline-item v-for="webNotification in webNotifications" :key="webNotification.created" small
                    fill-dot>
                    <template v-slot:icon>
                        <v-icon @click="reloadIfCurrentPath(webNotification.url)" color="secondary" small>
                            mdi-share
                        </v-icon>
                    </template>
                    <div class="px-5">
                        <div>
                            <v-icon small color="error" v-if="!webNotification.read" class="mr-1">mdi-alert-decagram
                            </v-icon>
                            <b :class="isErrorClass(webNotification.notificationType)">{{ webNotification.title }}</b></div>
                            <small> @{{ webNotification.created | DateFormatter }}</small>
                    </div>
                </v-timeline-item>
            </v-timeline>
        </v-card>
        <v-card v-if="webNotifications.length == 0">
            <v-card-text>
                {{ $t('label.noNotifications') }}
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
export default {
    name: "notification-component",
    data() {
        return {
            interval: null,
            updateTitle: null,
            errors: ["PAYMENT_FAILED", "EXTERNAL_DATA_PROCESS_ERROR"],
        };
    },
    methods: {
        ...mapActions("notification", ["fetchUnreadWebNotificationCount", "fetchWebNotifications", "readWebNotifications"]),
        checkUnreadNotifications() {
            this.fetchUnreadWebNotificationCount().then(() => {
                if (this.notificationChanged) {
                    this.fetchWebNotifications();
                }
            })
        },
        readUnreadNotifications() {
            if (this.unreadCount > 0) {
                setTimeout(() => {
                    this.readWebNotifications().then(() => {
                        this.fetchUnreadWebNotificationCount().then(() => {
                            this.fetchWebNotifications();
                        });
                    });
                }, 3000);

            }
        },
        changeTitle() {
            if (this.unreadCount > 0) {
                document.title = '(' + this.unreadCount + ') ' + document.title.replace(/ *\([^)]*\) */g, "");
            } else {
                document.title = document.title.replace(/ *\([^)]*\) */g, "");
            }
        },
        reloadIfCurrentPath(path) {
            if (this.$router.currentRoute.path === path) {
                window.location.reload();
            } else {
                this.$router.push(path);
            }
        },
        isErrorClass(notificationType){
            return this.errors.includes(notificationType) ? 'error--text' : ''
        }
    },
    computed: {
        ...mapFields("notification", ["unreadCount", "webNotifications", "notificationChanged"])
    },
    mounted() {
        this.interval = setInterval(() => this.checkUnreadNotifications(), 10000);
        this.updateTitle = setInterval(() => this.changeTitle(), 2000);
    },
    created() {
        this.fetchUnreadWebNotificationCount().then(() => {
            this.fetchWebNotifications();
        });
    },
}
</script>

<style scoped>
</style>