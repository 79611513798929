import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        transactions: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeGroupedTransactionCount(state, transactions) {
            state.transactions = transactions;
        },
    },
    actions: {
        fetchUserAddressesGroupedTransactionCount(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/analytics/groupedTransactionCount", payload).then((response) => {
                    if (response.data) {
                        store.commit("storeGroupedTransactionCount", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressGroupedTransactionCount(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post(`/analytics/groupedTransactionCount/${payload.address}`, payload.payload).then((response) => {
                    if (response.data) {
                        store.commit("storeGroupedTransactionCount", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
