import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        loggedUser: {
            email: null,
            firstName: null,
            lastName: null,
            userRole: null,
            pricingPlan: {
                pricingPlanType: null,
                hasPreviousYears: null,
                subscriptionStartDate: null,
                subscriptionEndDate: null,
                previousYearsPurchaseDate: null,
                hasDownloadedCurrentYearReport: null,
                hasDownloadedPreviousYearReport: null,
                transactionLimit: null,
                taxYearsAvailable: null,
            },
            settings: {
                language: null,
                currency: null,
                theme: null,
                nftEvaluationMethod: null,
                hideZeroValue: null,
                hideDust: null,
                costBasisMethod: null,
                country: null,
                taxCurrency: null,
                emoney: null,
                threshold: null,
                systemMailNotification: null,
                communicationMailNotification: null,
            },
            personalInvitationCode: null,
            externalTier: null
        },
        referrals: {
            invitedUsers: 0,
            premiumUsers: 0
        },
        visibility: false,
        synced: false
    },
    getters: {
        getField,
        isFreeUser(state){
            return state.loggedUser.userRole === 'USER' && state.loggedUser.pricingPlan.pricingPlanType === 'FREE';
        },
        isWithdrawalPeriodForSubscription(state){
            // Check if the user can request a withdrawal for subscription, if the user is a premium user from less than 15 days and never created a tax report
            return state.loggedUser.pricingPlan.subscriptionStartDate && (new Date(state.loggedUser.pricingPlan.subscriptionStartDate).getTime() + 15 * 24 * 60 * 60 * 1000) > new Date().getTime() && !state.loggedUser.pricingPlan.hasDownloadedCurrentYearReport;
        },
    },
    mutations: {
        updateField,
        setUserData(state, userData) {
            state.loggedUser = userData
            state.synced = true;
        },
        setUserReferrals(state, referralData) {
            state.referrals = referralData
        },
        changeVisibility(state, visibility){
            state.visibility = visibility;
        }
    },
    actions: {
        changeValueVisibility(store, visibility){
            store.commit('changeVisibility', visibility);
        },
        loadUserData(store) {
            return api.get('/user/me')
                .then((response) => {
                    store.commit('setUserData', response.data)
                });
        },
        updateUserData(store, payload) {
            return new Promise((resolve, reject) => {
                api.post("/user/update", payload)
                    .then((response) => {
                        if (response.data) {
                            store.commit("setUserData", response.data);
                        }
                        resolve(response);
                    })
                    .catch((err) => {
                        if (err && err.response && err.response.data) {
                            reject(err.response.data);
                        } else {
                            reject(err);
                        }
                    });
            });
        },
        deleteUserAccount() {
            return new Promise((resolve, reject) => {
                api.post("/user/delete")
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        if (err && err.response && err.response.data) {
                            reject(err.response.data);
                        } else {
                            reject(err);
                        }
                    });
            });
        },
        getReferrals(store){
            api.get('/user/referrals')
            .then((response) => {
                store.commit('setUserReferrals', response.data)
            });
        }
    }
}
