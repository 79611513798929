export default {
    namespaced: true,
    state: {
        alertMessage: '',
        showAlert: false,
        alertType: 'success',
        alertTime: -1
    },
    getters:{
        alertMessage: state => state.alertMessage,
        getShowAlert: state => state.showAlert,
        alertType: state => state.alertType,
        alertTime: state => state.alertTime    
    },
    mutations:{
        setAlertMessage: (state, message) => state.alertMessage = message,
        setShowAlert: (state, show) => state.showAlert = show,
        setAlertType: (state, type) => state.alertType = type,
        setAlertTime: (state, time) => state.alertTime = time,
    },
    actions:{
        createAlert(store, {message, type}) {
            store.commit('setAlertMessage', message);
            store.commit('setAlertType', type);
            store.commit('setAlertTime', 5000);
            store.commit('setShowAlert', true);
        },
        createAlertWithTime(store, {message, type, time}) {
            store.commit('setAlertMessage', message);
            store.commit('setAlertType', type);
            store.commit('setAlertTime', time);
            store.commit('setShowAlert', true);
        },
        hideAlert(store){
            store.commit('setShowAlert', false);
            store.commit('setAlertMessage', '');
        },
        createAlertNoTime(store, {message, type}) {
            store.commit('setAlertMessage', message);
            store.commit('setAlertType', type);
            store.commit('setAlertTime', -1);
            store.commit('setShowAlert', true);
        }
    }
}