<template>
  <v-snackbar
    v-model="showAlert"
    :color="alertType"
    :timeout="alertTime"
    multi-line
    bottom
    app
  >
    <div>
      {{ alertMessage }}
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn icon @click="hideAlert" v-bind="attrs">
        <v-icon>mdi-close-box</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("alert", [
      "alertMessage",
      "getShowAlert",
      "alertType",
      "alertTime",
    ]),
    showAlert: {
      get() {
        return this.getShowAlert;
      },
      set(val) {
        this.setShowAlert(val);
      },
    },
  },
  methods: {
    ...mapActions("alert", ["hideAlert"]),
    ...mapMutations("alert", ["setShowAlert"]),
  },
};
</script>

<style>
</style>