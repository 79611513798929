export const routes = [
    {
        path: '/',
        name: 'Home Page',
        component: () => import('@/views/HomePage.vue'),
        meta: { pageTitle: "Home", requiresAuth: true }
    },
    {
        path: '/pricing-plans',
        name: 'Pricing Plans Page',
        component: () => import('@/views/payment/PricingPlansPage.vue'),
        meta: { pageTitle: "Plans", requiresAuth: true }
    },
    {
        path: '/faq',
        name: 'FAQ Page',
        component: () => import('@/views/misc/FaqPage.vue'),
        meta: { pageTitle: "FAQ", requiresAuth: true }
    },
    //{
    //    path: '/referral',
    //    name: 'Referral Page',
    //    component: () => import('@/views/user/UserInviteFriendsPage.vue'),
    //    meta: { pageTitle: "Referral", requiresAuth: true }
    //},
    {
        path: '/add-connection',
        name: 'Add Connection Page',
        component: () => import('@/views/connection/AddConnectionPage.vue'),
        meta: { pageTitle: "Add Connection", requiresAuth: true }
    },
    {
        path: '/add-exchange/:exchange',
        name: 'Add Exchange Page',
        component: () => import('@/views/connection/AddExchangePage.vue'),
        meta: { pageTitle: "Add Exchange", requiresAuth: true }
    },
    {
        path: '/add-exchange-csv/:exchange',
        name: 'Add Exchange CSV Page',
        component: () => import('@/views/connection/AddExchangeCsvPage.vue'),
        meta: { pageTitle: "Add Exchange", requiresAuth: true }
    },
    {
        path: '/add-address/:blockchain',
        name: 'Add Address Page',
        component: () => import('@/views/connection/AddPersonalAddressPage.vue'),
        meta: { pageTitle: "Add Address", requiresAuth: true }
    },
    {
        path: '/overview/:address',
        name: 'Address Overview Page',
        component: () => import('@/views/address/AddressOverviewPage.vue'),
        meta: { pageTitle: "Address Overview", requiresAuth: true }
    },
    //{
    //    path: '/analytics/:address',
    //    name: 'Address Analytics Page',
    //    component: () => import('@/views/address/AddressAnalyticsPage.vue'),
    //    meta: { pageTitle: "Address Analytics", requiresAuth: true }
    //},
    {
        path: '/overview',
        name: 'User Overview Page',
        component: () => import('@/views/user/UserOverviewPage.vue'),
        meta: { pageTitle: "Overview", requiresAuth: true }
    },
    //{
    //    path: '/analytics',
    //    name: 'User Analytics Page',
    //    component: () => import('@/views/user/UserAnalyticsPage.vue'),
    //    meta: { pageTitle: "Analytics", requiresAuth: true }
    //},
    {
        path: '/tax-center',
        name: 'User Tax Center Page',
        component: () => import('@/views/user/UserTaxCenterPage.vue'),
        meta: { pageTitle: "Tax Center", requiresAuth: true }
    },
    {
        path: '/create-tax-report',
        name: 'Create Tax Report Page',
        component: () => import('@/views/user/UserCreateTaxReportPage.vue'),
        meta: { pageTitle: "Create Tax Report", requiresAuth: true }
    },
    //{
    //    path: '/financial-report',
    //    name: 'User Financial Report Page',
    //    component: () => import('@/views/user/UserFinancialReportPage.vue'),
    //    meta: { pageTitle: "Financial Report", requiresAuth: true }
    //},
    {
        path: '/connection-management',
        name: 'Connection Management Page',
        component: () => import('@/views/connection/ConnectionManagementPage.vue'),
        meta: { pageTitle: "Connections", requiresAuth: true }
    },
    //{
    //    path: '/tools/address-book',
    //    name: 'Address Book Tool Page',
    //    component: () => import('@/views/tool/AddressBookToolPage.vue'),
    //    meta: { pageTitle: "Address Book", requiresAuth: true }
    //},
    {
        path: '/settings',
        name: 'User Settings Page',
        component: () => import('@/views/user/UserSettingsPage.vue'),
        meta: { pageTitle: "Settings", requiresAuth: true }
    },
    //{
    //    path: '/tools/coin-converter',
    //    name: 'Converter Tool Page',
    //    component: () => import('@/views/tool/ConverterToolPage.vue'),
    //    meta: { pageTitle: "Coin Converter", requiresAuth: true }
    //},
    //{
    //    path: '/tools/interest-calculator',
    //    name: 'Interest Calculator Tool Page',
    //    component: () => import('@/views/tool/InterestCalculatorToolPage.vue'),
    //    meta: { pageTitle: "Interest Calculator", requiresAuth: true }
    //},
    {
        path: '/payment/success',
        name: 'Payment Success Page',
        component: () => import('@/views/payment/PaymentSuccessPage.vue'),
        meta: { pageTitle: "Success", requiresAuth: true }
    },
    {
        path: '/payment/cancel',
        name: 'Payment Cancel Page',
        component: () => import('@/views/payment/PaymentCancelPage.vue'),
        meta: { pageTitle: "Cancel", requiresAuth: true }
    },
    {
        path: '/about',
        name: 'About Page',
        component: () => import('@/views/misc/AboutPage.vue'),
        meta: { pageTitle: "About", requiresAuth: true }
    },
    {
        path: '/test',
        name: 'Test Page',
        component: () => import('@/views/misc/TestPage.vue'),
        meta: { pageTitle: "Test", requiresAuth: true }
    },
    //{
    //    path: '/news',
    //    name: 'News Feed Page',
    //    component: () => import('@/views/market/NewsFeedPage.vue'),
    //    meta: { pageTitle: "News", requiresAuth: true }
    //},
    {
        path: '/address-not-found',
        name: 'Address Not Found Page',
        component: () => import('@/views/error/AddressNotFoundPage.vue'),
        meta: { pageTitle: "Address Not Found", requiresAuth: true }
    },
    {
        path: '*',
        name: '404 Page',
        component: () => import('@/views/error/404Page.vue'),
        meta: { pageTitle: "404", requiresAuth: false }
    },
]
