<template>
  <v-list dense>
    <v-list-item two-line>
      <v-list-item-avatar>
        <address-avatar-component :name="loggedUser.nickname" size="40"></address-avatar-component>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ loggedUser.nickname }}</v-list-item-title>
        <v-list-item-subtitle>{{ loggedUser.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item link @click="$router.push('/settings')">
      <v-list-item-icon>
        <v-icon>mdi-account-cog</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{$t("label.settings")}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!--
    <v-list-item link @click="$router.push('/referral')">
      <v-list-item-icon>
        <v-icon>mdi-gift</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{$t("label.inviteFriends")}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    -->
    <v-list-item link href="mailto:support@okipo.io">
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{$t("label.support")}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!--
    <v-list-item link @click="$router.push('/faq')">
      <v-list-item-icon>
        <v-icon>mdi-help-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{$t("label.faq")}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    -->
    <v-list-item key="logout" link @click="logout()">
      <v-list-item-icon>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="my-3"></v-divider>
    <v-list-item>
      <v-spacer/>
      <social-component class="pb-1"></social-component>
      <v-spacer/>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SocialComponent from './SocialComponent.vue';
import AddressAvatarComponent from './AddressAvatarComponent.vue';
export default {
  name: 'profile-dropdown-component',
  components: {
    SocialComponent,
    AddressAvatarComponent,
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    logout() {
      if (this.$keycloak){
        this.$keycloak.logoutFn();
      } else {
        this.$router.push("/");
      }
    },
    goToWebsite(){
      window.open("https://www.okipo.io", "_blank");
    }
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
  },
};
</script>

<style scoped>
</style>
