import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        totalTransactions: null
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeTotalTransactions(state, totalTransactions) {
            state.totalTransactions = totalTransactions;
        },
    },
    actions: {
        fetchUserAddressesTotalTransactions(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/transaction/total").then((response) => {
                    if (response.data) {
                        store.commit("storeTotalTransactions", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressTotalTransactions(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/transaction/total/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeTotalTransactions", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        updateTransaction(store, transaction) {
            return new Promise((resolve, reject) => {
                return api.post(`/userAddressTransaction/update`, transaction).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        }
    }

}
