import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        totalNfts: 0,
        nftKpis: {
            totalRealizedRoi: 0,
            totalRealizedProfit: 0,
        },
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeTotalNfts(state, totalNfts) {
            state.totalNfts = totalNfts;
        },
        storeNftKpis(state, nftKpis) {
            state.nftKpis = nftKpis;
        }
    },
    actions: {
        fetchUserAddressesTotalNfts(store) {
            return new Promise((resolve, reject) => {
                return api.get("/analytics/nft/total").then((response) => {
                    if (response.data) {
                        store.commit("storeTotalNfts", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressTotalNfts(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/analytics/nft/total/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeTotalNfts", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchUserAddressesNftKpis(store) {
            return new Promise((resolve, reject) => {
                return api.get("/financial/nft/kpi").then((response) => {
                    if (response.data) {
                        store.commit("storeNftKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressNftKpis(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/financial/nft/kpi/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeNftKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
