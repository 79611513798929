import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        kpis: {
            totalRealizedRoi: 0,
            totalRealizedProfit: 0,
            totalNetCashFlow: 0,
            totalNetIncome: 0,
        },
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeKpis(state, kpis) {
            state.kpis = kpis;
        }
    },
    actions: {
        fetchUserAddressesKpis(store) {
            return new Promise((resolve, reject) => {
                return api.get("/financial/kpi").then((response) => {
                    if (response.data) {
                        store.commit("storeKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAddressKpis(store, address) {
            return new Promise((resolve, reject) => {
                return api.get(`/financial/kpi/${address}`).then((response) => {
                    if (response.data) {
                        store.commit("storeKpis", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
