import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        taxYear: null,
        currency: null,
        country: null,
        costBasisMethod: null,
        emoney: null,
        threshold: null,
        totalTransactions: 0,
        taxYearTransactions: 0,
        availableTaxYears: [],
        taxDocuments: [],
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeAvailableTaxYears(state, availableTaxYears) {
            state.availableTaxYears = availableTaxYears;
            state.taxYear = availableTaxYears[0];
        },
        storeTotalTransactions(state, totalTransactions) {
            state.totalTransactions = totalTransactions;
        },
        storeTaxYearTransactions(state, taxYearTransactions) {
            state.taxYearTransactions = taxYearTransactions;
        },
        storeTaxDocuments(state, taxDocuments) {
            state.taxDocuments = taxDocuments;
        }
    },
    actions: {
        fetchCurrentTaxYearTransactions(store) {
            return new Promise((resolve, reject) => {
                return api.get("/tax/current-tax-year-transactions").then((response) => {
                    if (response.data) {
                        store.commit("storeTotalTransactions", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchTaxYearTransactions(store, taxYear) {
            return new Promise((resolve, reject) => {
                return api.get(`/tax/tax-year-transactions/${taxYear}`).then((response) => {
                    if (response.data) {
                        store.commit("storeTaxYearTransactions", response.data)
                    } else {
                        store.commit("storeTaxYearTransactions", 0)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchAvailableTaxYears(store) {
            return new Promise((resolve, reject) => {
                return api.get("/tax/available-tax-years").then((response) => {
                    if (response.data) {
                        store.commit("storeAvailableTaxYears", response.data.taxYears)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        fetchTaxDocuments(store, payload){
            return new Promise((resolve, reject) => {
                return api.get(`/document/tax/${payload}`).then((response) => {
                    if (response.data) {
                        store.commit("storeTaxDocuments", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        requestTaxReport(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/tax/create-tax-report", payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            });
        },
        askForAccountant() {
            return new Promise((resolve, reject) => {
                return api.post("/tax/ask-for-accountant").then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            });
        },
        deleteTaxDocument(store, documentId) {
            return new Promise((resolve, reject) => {
                return api.post(`/tax/document/delete/${documentId}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        deleteTaxDocuments(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post(`/tax/document/delete`, payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
